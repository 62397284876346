<script setup>

</script>
<template>
    
    <div class="ajv layout layout--default">
        <DebugOverlay/>
        <DefaultHeader  />
        <main class="content-wrapper">
            <NuxtErrorBoundary>
           <slot />

           </NuxtErrorBoundary>
        </main>
        <DefaultFooter/>
        <!-- <DefaultBackground /> -->
    </div>
   
</template>
<style lang="scss">
     @use '/assets/styles/scss/layouts/default';
</style>